<template>
    <div class="row">
        <div class="col-12">
            <b-card header="Manager User">
                <div class="pl-3 pr-3 pb-3">
                    <b-row class="mb-3">
                        <b-col>
                            <VDropdown>
                                <b-button variant="secondary" class="mb-2">
                                    <feather-icon icon="SearchIcon" class="mr-2" size="15" />
                                    <span class="align-middle">Search-items</span>
                                </b-button>
                                <!-- This will be the content of the popover -->
                                <template #popper>
                                    <b-row>
                                        <b-col class="pl-0 mt-1 ml-3">
                                            <b-form-group label="user id" label-for="userId">
                                                <b-form-input placeholder="user id" v-model="queryCondition.id" />
                                            </b-form-group>
                                        </b-col>
                                    </b-row>

                                    <b-row>
                                        <b-col class="pl-0 mt-1 ml-3">
                                            <b-form-group label="email pattern" label-for="emailPattern">
                                                <b-form-input placeholder="email pattern" v-model="queryCondition.emailPattern" />
                                            </b-form-group>
                                        </b-col>
                                    </b-row>

                                    <b-row>
                                        <b-col class="pl-0 mt-1 ml-3">
                                            <b-form-group label="user token" label-for="userToken">
                                                <b-form-input placeholder="user token" v-model="queryCondition.userToken" />
                                            </b-form-group>
                                        </b-col>
                                    </b-row>

                                    <b-row>
                                        <b-col class="pl-0 mt-1 ml-3">
                                            <b-form-group label="credit user id" label-for="creditUserId">
                                                <b-form-input placeholder="credit user id" v-model="queryCondition.creditUserId" />
                                            </b-form-group>
                                        </b-col>
                                    </b-row>

                                    <b-row>
                                        <b-col class="pl-0 mt-1 ml-3">
                                            <b-form-group label="status" label-for="status">
                                                <v-select
                                                    id="queryType"
                                                    v-model="queryCondition.forbidden"
                                                    :options="['All', 'Forbidden', 'Active']"
                                                />
                                            </b-form-group>
                                        </b-col>
                                    </b-row>

                                    <b-row class="mb-1 mt-3">
                                        <b-col>
                                            <b-button variant="secondary" v-close-popper="true" @click="search"> Search</b-button>
                                            <b-button class="ml-2" variant="secondary" @click="clearQueryCondition"> Clear</b-button>
                                        </b-col>
                                    </b-row>
                                </template>
                            </VDropdown>

                            <b-button class="mb-2 ml-2" variant="secondary" @click="downloadTable">Download CSV</b-button>
                        </b-col>
                    </b-row>

                    <b-overlay :show="table_show_overlay" rounded="sm">
                        <vue-good-table
                            ref="remote_q_table"
                            @on-page-change="onPageChange"
                            @on-per-page-change="onPerPageChange"
                            :totalRows="totalRows"
                            :columns="columns"
                            :rows="row_data"
                            :sort-options="{ enabled: false }"
                            :pagination-options="{
                                enabled: true,
                                mode: 'pages',
                                perPage: 10,
                                perPageDropdown: [5, 10, 20, 50, 100, 250, 500, 1000],
                                setCurrentPage: 1,
                            }"
                        >
                            <template slot="table-row" slot-scope="props">
                                <span v-if="props.column.field === 'roles'">
                                    <b-badge v-for="role in props.row[props.column.field]" variant="primary" class="ml-1 mr-1" :key="role">
                                        {{ role }}</b-badge
                                    >
                                </span>

                                <span v-else-if="props.column.field === 'forbidden'">
                                    <b-badge v-if="!props.row['forbidden']" pill variant="success" class="mr-3">active</b-badge>
                                    <b-badge v-else pill class="mr-3">forbidden</b-badge>
                                </span>

                                <span v-else-if="props.column.field === 'msnn_balance'">
                                    {{props.row[props.column.field] }}
                                </span>

                                <span v-else-if="props.column.field === 'msntt_balance'">
                                    {{ props.row[props.column.field] }}
                                </span>

                                <span v-else-if="props.column.field === 'node_count_info'">
                                    {{ props.row["alive_node_count"] + " / " + props.row["total_node_count"] }}
                                </span>

                                <span v-else-if="props.column.field === 'pdn_balance'">
                                    {{ $tools.tokenAmountParser.parserToMoneyFormat(props.row[props.column.field], 12,2,true) }}
                                </span>

                                <span v-else-if="props.column.field === 'register_country_code'">
                                    {{ props.row["register_country_code"] === ALL_Country ? "" : GetFullCountry(props.row["register_country_code"]) }}
                                </span>

                                <span v-else-if="props.column.field === 'created_unixtime'">
                                    {{ moment.unix(props.row[props.column.field]).utc().format("YYYY-MM-DD HH:mm:ss") }}
                                </span>

                                <span v-else-if="props.column.field === 'action1'">
                                    <b-button variant="primary" @click="open_row_view_edit(props.row.originalIndex)">Edit/Delete</b-button>
                                </span>

                                <span v-else>
                                    {{ props.row[props.column.field] }}
                                </span>
                            </template>
                        </vue-good-table>
                    </b-overlay>

                    <!-- modal_row_view_update-->
                    <b-modal
                        id="modal_row_view_update"
                        size="lg"
                        @hide="handleHide"
                        title="view/edit"
                        cancel-variant="secondary"
                        cancel-title="Close"
                        centered
                    >
                        <b-overlay :show="modal_row_view_overlay" rounded="sm">
                            <b-form>
                                <b-form-group>
                                    <label>Email:</label>
                                    <b-form-input id="email" type="text" v-model="focus.email" disabled />
                                </b-form-group>

                                <b-form-group>
                                    <label>PullZone limit:</label>
                                    <b-form-input id="pullZoneLimit" type="text" v-model="updateUserData.pullZoneLimit" />
                                </b-form-group>

                                <b-form-group v-if="$store.state.auth.my_roles.includes('admin')">
                                    <label>Roles:</label>
                                    <b-form-checkbox-group
                                        v-model="updateUserData.roles"
                                        :options="rolesOption"
                                        switches
                                        class="demo-inline-spacing"
                                    />
                                </b-form-group>

                                <b-form-group>
                                    <label>Forbidden:</label>
                                    <b-form-checkbox v-model="updateUserData.forbidden" name="check-button" class="custom-control-danger" switch>
                                    </b-form-checkbox>
                                </b-form-group>
                            </b-form>
                        </b-overlay>

                        <template #modal-footer>
                            <b-row class="w-100">
                                <b-col class="text-left">
                                    <b-button variant="danger" @click="deleteUser">Delete</b-button>
                                </b-col>
                                <b-col cols="8" class="text-right">
                                    <b-button variant="primary" class="mr-3" @click="updateUser">Update</b-button>
                                    <b-button variant="secondary" @click="$bvModal.hide('modal_row_view_update')">Cancel</b-button>
                                </b-col>
                            </b-row>
                        </template>
                    </b-modal>
                </div>
            </b-card>
        </div>
    </div>
</template>

<script>
import {
    BCard,
    BCardFooter,
    BRow,
    BCol,
    BInputGroup,
    BFormInput,
    BInputGroupPrepend,
    BButton,
    BOverlay,
    BBadge,
    BFormGroup,
    BFormInvalidFeedback,
    BAlert,
    BForm,
    BFormCheckbox,
    BFormCheckboxGroup,
    BPopover,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import vSelect from "vue-select";

import {
    ALL_Continent,
    ALL_Country,
    CountryMap,
    ContinentMap,
    GetFullCountry,
    GetFullContinent,
    ContinentOption,
    CountryOption,
} from "../../location/location";

import moment from "moment";
import FileSaver from 'file-saver';
import json2csv from 'json2csv';

export default {
    components: {
        BCard,
        BCardFooter,
        BRow,
        BCol,
        BInputGroup,
        BFormInput,
        BInputGroupPrepend,
        BButton,
        BOverlay,
        BBadge,
        BFormGroup,
        BFormInvalidFeedback,
        BAlert,
        BForm,
        BFormCheckbox,
        BFormCheckboxGroup,
        BPopover,
        VueGoodTable,
        vSelect,
    },

    methods: {
        GetFullCountry,
        GetFullContinent,
        clearQueryCondition() {
            this.queryCondition = {
                id: null,
                emailPattern: null,
                email: null,
                forbidden: "All",
                creditUserId: null,
                parentUserId: null,
            };
        },
        onPageChange(params) {
            this.limit = params.currentPerPage;
            this.offset = (params.currentPage - 1) * params.currentPerPage;
            this.refreshTable();
        },

        onPerPageChange(params) {
            this.limit = params.currentPerPage;
            this.offset = (params.currentPage - 1) * params.currentPerPage;
            this.refreshTable();
        },

        async search() {
            this.$refs["remote_q_table"].reset();
            this.offset = 0;
            await this.refreshTable();
        },

        async getAuthConfig() {
            let resp = await this.$api.user.getAuthConfig();
            if (resp.err !== null) {
                this.$bvToast.toast(resp.err, {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            if (resp.result.meta_status < 0) {
                this.$bvToast.toast(resp.result.meta_message, {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            this.rolesOption = resp.result.roles;
        },
        async refreshTable() {
            this.table_show_overlay = true;

            let userId = this.queryCondition.id ? parseInt(this.queryCondition.id) : null;
            let emailPattern = this.queryCondition.emailPattern ? this.queryCondition.emailPattern : null;
            let userToken = this.queryCondition.userToken ? this.queryCondition.userToken : null;
            let forbidden = null;
            if (this.queryCondition.forbidden && this.queryCondition.forbidden === "Forbidden") {
                forbidden = true;
            } else if (this.queryCondition.forbidden && this.queryCondition.forbidden === "Active") {
                forbidden = false;
            }
            let creditUserId = this.queryCondition.creditUserId ? parseInt(this.queryCondition.creditUserId) : null;

            let resp = await this.$api.user.queryUser(
                userId,
                emailPattern,
                userToken,
                forbidden,
                creditUserId,
                this.limit,
                this.offset,
                this.$store.state.auth.my_web_token
            );
            this.table_show_overlay = false;

            if (resp.err !== null) {
                this.$bvToast.toast(resp.err, {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            if (resp.result.meta_status < 0) {
                this.$bvToast.toast(resp.result.meta_message, {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            //response user ids
            let userIdArray = [];
            for (let i = 0; i < resp.result.user_list.length; i++) {
                userIdArray.push(resp.result.user_list[i].id);
            }
            //get user token
            this.table_show_overlay = true;
            let userTokenMsnttMap=await this.getUserMesonRewardTestBalance(userIdArray);
            this.table_show_overlay = false;
            if (userTokenMsnttMap !== null) {
                for (let i = 0; i < resp.result.user_list.length; i++) {
                    if (userTokenMsnttMap[resp.result.user_list[i].id]){
                        resp.result.user_list[i].msntt_balance = userTokenMsnttMap[resp.result.user_list[i].id].balance;
                    }else{
                        resp.result.user_list[i].msntt_balance = "0";
                    }
                    
                }
            }

            this.table_show_overlay = true;
            let userTokenMsnnMap=await this.getUserMesonRewardMainBalance(userIdArray);
            this.table_show_overlay = false;
            if (userTokenMsnnMap !== null) {
                for (let i = 0; i < resp.result.user_list.length; i++) {
                    if (userTokenMsnnMap[resp.result.user_list[i].id]){
                        resp.result.user_list[i].msnn_balance = userTokenMsnnMap[resp.result.user_list[i].id].balance;
                    }else{
                        resp.result.user_list[i].msnn_balance = "0";
                    }
                }
            }

            this.table_show_overlay = true;
            let userTokenPdnMap=await this.getUserTokenPdnBalance(userIdArray);
            this.table_show_overlay = false;
            if (userTokenPdnMap !== null) {
                for (let i = 0; i < resp.result.user_list.length; i++) {
                    if (userTokenPdnMap[resp.result.user_list[i].id]){
                        resp.result.user_list[i].pdn_balance = userTokenPdnMap[resp.result.user_list[i].id].balance;
                    }else{
                        resp.result.user_list[i].pdn_balance = "0";
                    }
                }
            }

            //get user node count
            this.table_show_overlay = true;
            let userNodeCountMap = await this.getUserNodeCount(userIdArray);
            this.table_show_overlay = false;
            if (userNodeCountMap !== null) {
                for (let i = 0; i < resp.result.user_list.length; i++) {
                    resp.result.user_list[i].alive_node_count = userNodeCountMap[resp.result.user_list[i].id].alive;
                    resp.result.user_list[i].total_node_count = userNodeCountMap[resp.result.user_list[i].id].total;
                }
            }

            this.row_data = resp.result.user_list;
            this.totalRows = resp.result.count;
        },

        async getUserMesonRewardTestBalance(userIdArray) {
            let resp = await this.$api.user.userMesonRewardBalance(userIdArray,"MESON_REWARD_TEST", this.$store.state.auth.my_web_token);

            if (resp.err !== null) {
                this.$bvToast.toast(resp.err, {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            if (resp.result.meta_status < 0) {
                this.$bvToast.toast(resp.result.meta_message, {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            return resp.result.result;
        },

        async getUserMesonRewardMainBalance(userIdArray) {
            let resp = await this.$api.user.userMesonRewardBalance(userIdArray,"MESON_REWARD_MAIN", this.$store.state.auth.my_web_token);

            if (resp.err !== null) {
                this.$bvToast.toast(resp.err, {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            if (resp.result.meta_status < 0) {
                this.$bvToast.toast(resp.result.meta_message, {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            return resp.result.result;
        },

        async getUserTokenPdnBalance(userIdArray) {
            let resp = await this.$api.user.userMesonCreditBalance(userIdArray,"MESON_CREDIT", this.$store.state.auth.my_web_token);

            if (resp.err !== null) {
                this.$bvToast.toast(resp.err, {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            if (resp.result.meta_status < 0) {
                this.$bvToast.toast(resp.result.meta_message, {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            return resp.result.result;
        },

        async getUserNodeCount(userIdArray) {
            let resp = await this.$api.node.queryUserNodeCount(userIdArray, this.$store.state.auth.my_web_token);

            if (resp.err !== null) {
                this.$bvToast.toast(resp.err, {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            if (resp.result.meta_status < 0) {
                this.$bvToast.toast(resp.result.meta_message, {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            return resp.result.result;
        },

        open_row_view_edit(rowid) {
            this.focus = this.row_data[rowid];
            this.updateUserData.forbidden = this.focus.forbidden;
            this.updateUserData.parentUserId = this.focus.parent_user_id;
            this.updateUserData.pullZoneLimit = this.focus.pull_zone_limit;
            this.updateUserData.roles = this.focus.roles;
            this.$bvModal.show("modal_row_view_update");
        },
        handleHide(bvModalEvt) {
            if (this.remote_updating) {
                bvModalEvt.preventDefault();
            }
        },

        async updateUser() {
            let forbidden = this.updateUserData.forbidden;
            let roles = this.updateUserData.roles;
            if (!this.$store.state.auth.my_roles.includes("admin")) {
                roles = null;
            }
            let permissions = null;
            let pullZoneLimit = parseInt(this.updateUserData.pullZoneLimit);

            let resp = await this.$api.user.updateUser(
                this.focus.id,
                forbidden,
                roles,
                permissions,
                pullZoneLimit,
                this.$store.state.auth.my_web_token
            );

            if (resp.err !== null) {
                this.$bvToast.toast(resp.err, {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            if (resp.result.meta_status < 0) {
                this.$bvToast.toast(resp.result.meta_message, {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            this.$bvToast.toast("user updated", {
                title: `Success`,
                variant: "success",
                solid: true,
                toaster: "b-toaster-top-center",
            });
            this.refreshTable();
            this.$bvModal.hide("modal_row_view_update");
        },

        async deleteUser() {
            let email = this.focus.email;
            let id = this.focus.id;
            let value = await this.$bvModal.msgBoxConfirm(`Please confirm that you want to delete User [${email}]`, {
                title: "Delete User",
                okVariant: "danger",
                okTitle: "Delete",
                cancelTitle: "No",
                cancelVariant: "secondary",
                hideHeaderClose: false,
                centered: true,
            });
            if (value) {
                let resp = await this.$api.user.deleteUser(id, this.$store.state.auth.my_web_token);

                if (resp.err !== null) {
                    this.$bvToast.toast(resp.err, {
                        title: `Error`,
                        variant: "danger",
                        solid: true,
                        toaster: "b-toaster-top-center",
                    });
                    return;
                }

                if (resp.result.meta_status < 0) {
                    this.$bvToast.toast(resp.result.meta_message, {
                        title: `Error`,
                        variant: "danger",
                        solid: true,
                        toaster: "b-toaster-top-center",
                    });
                    return;
                }

                this.$bvToast.toast("user deleted", {
                    title: `Success`,
                    variant: "success",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                this.refreshTable();
                this.$bvModal.hide("modal_row_view_update");
            }
        },

        downloadTable(){
            // console.log(1111)
            //gen csv
            const csvData=[]
            for (let i = 0; i < this.row_data.length; i++) {
                csvData.push({
                    id:this.row_data[i].id,
                    email:this.row_data[i].email,
                    credit_user_id:this.row_data[i].credit_user_id,
                    msntt_balance:this.row_data[i].msntt_balance,
                    msnn_balance:this.row_data[i].msnn_balance,
                    pdn_balance:this.$tools.tokenAmountParser.parserToMoneyFormat(this.row_data[i].pdn_balance, 12,2,true),
                    node_alive:this.row_data[i].alive_node_count,
                    node_total:this.row_data[i].total_node_count,
                    forbidden:this.row_data[i].forbidden,
                    continent_code:this.row_data[i].register_continent_code,
                    country_code:GetFullCountry(this.row_data[i].register_country_code),
                    created:moment.unix(this.row_data[i].created_unixtime).utc().format("YYYY-MM-DD HH:mm:ss"),
                })
            }
            const fields = ['id', 'email', 'credit_user_id','msntt_balance','msnn_balance','pdn_balance','node_alive','node_total','forbidden',"continent_code","country_code","created"];
            const BOM = "\uFEFF"
            const result = json2csv.parse(csvData,{fields:fields})
            //download
            var blob = new Blob([BOM+result], {type: "text/csv;charset=utf-8"});
            FileSaver.saveAs(blob, `user_info_${moment().format("YYYY_MM_DD_HH_mm_ss")}.csv`);
        }
    },
    mounted() {
        this.refreshTable();
        this.getAuthConfig();
    },
    data() {
        return {
            ALL_Country:ALL_Country,
            moment: moment,

            columns: [
                {
                    label: "ID",
                    field: "id",
                    tdClass: "text-center",
                    thClass: "text-center",
                },
                {
                    label: "Email",
                    field: "email",
                    type: "text",
                    tdClass: "text-center",
                    thClass: "text-center",
                },
                {
                    label: "Roles",
                    field: "roles",
                    type: "text",
                    tdClass: "text-center",
                    thClass: "text-center",
                },
                {
                    label: "CreditUserId",
                    field: "credit_user_id",
                    type: "text",
                    tdClass: "text-center",
                    thClass: "text-center",
                },
                {
                    label: "PullZoneLimit",
                    field: "pull_zone_limit",
                    type: "text",
                    tdClass: "text-center",
                    thClass: "text-center",
                },
                {
                    label: "Balance_MSNTT",
                    field: "msntt_balance",
                    type: "text",
                    tdClass: "text-center",
                    thClass: "text-center",
                },
                {
                    label: "Balance_MSNN",
                    field: "msnn_balance",
                    type: "text",
                    tdClass: "text-center",
                    thClass: "text-center",
                },
                {
                    label: "Node Count(Alive/Total)",
                    field: "node_count_info",
                    type: "text",
                    tdClass: "text-center",
                    thClass: "text-center",
                },
                {
                    label: "Balance_MC",
                    field: "pdn_balance",
                    type: "text",
                    tdClass: "text-center",
                    thClass: "text-center",
                },
                {
                    label: "Active",
                    field: "forbidden",
                    type: "text",
                    tdClass: "text-center",
                    thClass: "text-center",
                },
                // {
                //     label: "Token",
                //     field: "token",
                //     type: "text",
                //     tdClass: "text-center",
                //     thClass: "text-center",
                // },
                {
                    label: "Location",
                    field: "register_country_code",
                    type: "text",
                    tdClass: "text-center",
                    thClass: "text-center",
                },
                {
                    label: "Created",
                    field: "created_unixtime",
                    type: "text",
                    tdClass: "text-center",
                    thClass: "text-center",
                },
                {
                    label: "Action",
                    field: "action1",
                    tdClass: "text-center",
                    thClass: "text-center",
                },
            ],
            row_data: [],
            limit: 10,
            offset: 0,
            totalRows: 0,

            table_show_overlay: false,

            queryCondition: {
                id: null,
                emailPattern: null,
                userToken: null,
                forbidden: "All",
                creditUserId: null,
                parentUserId: null,
            },

            updateUserData: {
                parentUserId: null,
                forbidden: null,
                roles: null,
                permissions: null,
                pullZoneLimit: null,
            },

            rolesOption: [],
            register_show_overlay: false,
            modal_row_view_overlay: false,

            focus: {},
        };
    },
};
</script>
